<script>
export default {
  methods: {
    scrolledBottom(el) {
      return ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight);
    },
    /**
     * this.pagination should be defined and taken from store.
     * ...mapGetters('Store', {pagination: 'pagination'})
     * @returns {boolean}
     */
    isLastPage() {
      return this.pagination?.current_page === this.pagination?.total_pages;
    },
    scrollShouldLoadMore(el) {
      return this.scrolledBottom(el) && !this.isLastPage();
    }
  }
};
</script>
