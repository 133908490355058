<template>
  <plan-filter-list :showPackageButtons="true" :showPagination="true">
  </plan-filter-list>
</template>
<script>
// Components
import PlanFilterList from "@/views/Plan/Components/PlanFilterList";
import { mapGetters } from "vuex";

export default {
  name: "plans",
  props: {
    packId: {
      type: [String, Number]
    }
  },
  components: {
    PlanFilterList,
  },
  data() {
    return {
      addType: [
        { type: 1, name: "pop" },
        { type: 2, name: "native" },
        { type: 3, name: "push" }
      ],
      deviceIcons: [
        { name: "TV", icon: "fa fa-tv" },
        { name: "Computer", icon: "fa fa-desktop" },
        { name: "Mobile", icon: "fa fa-mobile-alt" },
        { name: "Tablet", icon: "fa fa-tablet-alt" },
        { name: "Bot", icon: "fa fa-robot" },
        { name: "Camera", icon: "fa fa-camera" },
        { name: "Console", icon: "fa fa-gamepad" },
        { name: "Netbook", icon: "fa fa-laptop" },
        { name: "Glass", icon: "fa fa-glasses" },
        { name: "SetTopBox", icon: "fa fa-square" },
        { name: "Watch", icon: "fa fa-clock" }
      ],
      tab: "all"
    };
  },
  computed: {
    ...mapGetters("User", ["getUser"]),
    ...mapGetters("Plan", {
      "getPackages": 'getList', 
      getPackagePagination: "pagination",
    }),
    ...mapGetters("Country", [
      "getCountries",
      "getCountryById",
      "getCountryCodes",
      "getAllCountryCodesForMap"
    ]),
    ...mapGetters("DeviceType", ["getDeviceTypes"]),
    ...mapGetters("DeviceType", ["getDeviceTypeById"])
  },
  watch: {
    tab() {
      this.selectPage(1);
    }
  },
  methods: {
    selectPage(val) {
      let filter = { page: val, filters: [] };
      if (this.tab == "my")
        filter.filters.push({
          key: "type",
          value: "my"
        });
      this.$store.dispatch("Plan/getPage", filter);
    },
    createPackage() {
      this.$router.push({ name: "create_package" });
    },
    getFilters() {
      return [{
        type: 'radio',
        options: [{
          name: this.getTrans('messages.all'),
          value: '',
        },
        {
          name: this.getTrans('messages.public'),
          value: 'public',
        },
        {
          name: this.getTrans('messages.private'),
          value: 'private',
        }],
        class: 'col-4',
      }]
    },
    filtersUpdated(filters) {
      this.selectPage(filters)
    }
  },
  mounted() {
    this.selectPage(1);
    this.$store.dispatch("Country/get").then(() => {
      this.$forceUpdate();
    });
  }
};
</script>
