<script>
export default {
  methods: {
    /**
     * this.filter should be defined
     * @returns {{}}
     */
    collectFilters() {
      const filter = {};
      for (let key in this.filter) {
        if (this.filter[key]) {
          filter[key] = this.filter[key];
        }
      }
      return filter;
    },
  }
};
</script>
