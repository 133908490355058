<template>
  <div class="d-flex" :class="wrapperClass">
    <base-pagination v-if="pagination"
      v-model="pagination.current_page"
      :per-page="pagination.per_page"
      :total="pagination.total"
      @update:modelValue="change"
    ></base-pagination>
  </div>
</template>
<script>

import BasePagination from "@/components/BasePagination";

export default {
  name: 'store-pagination',
  emits: ['update:modelValue'],
  components: {
    'base-pagination': BasePagination,
  },
  props: {
    store: {
      type: String,
      required: true
    },
    wrapperClass: {
      type: String,
      default: 'justify-content-center'
    }
  },
  computed: {
    pagination() {
      return this.$store.getters[`${this.store}/pagination`];
    }
  },
  methods: {
    change(item) {
      this.$emit('update:modelValue', item);
    }
  },
};
</script>
