<template>
  <div class="card mb-0 m-3">
    <div class="card-body d-flex align-items-center">
      <slot name="icon">
        <i class="fas fa-info-circle fa-2x text-primary"></i>
      </slot>
      <div class="ml-2">
        <slot>
          {{getTrans('messages.nothing_found')}}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NothingFound",
}
</script>
