<template>
  <div class="selectable-card" @click="click"
       :class="{'is-selected':selected}">
    <i class="fas fa-check-circle selectable-card__icon" v-if="selected"></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    selected: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
}
</script>
